<template>
  <Checkout />
</template>

<script>
import Checkout from "@/views/retailer/Checkout.vue";
export default {
  components: { Checkout },
};
</script>

<style lang="scss" scoped></style>
